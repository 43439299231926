.btn.btn-icon {
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50%;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-showPass {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.btn.btn-icon.polos:focus {
  box-shadow: none;
}

.btn.btn-icon.polos:hover {
  background-color: whitesmoke;
}

.chat-card .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 10px;
  position: relative;
}

.chat-card .msg img {
  width: 100px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
}

.chat-card .received-chat .msg {
  background: #dfe9ff;
  border-radius: 0 5px 5px 5px;
}

.chat-card .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-bottom-color: #dfe9ff;
}

.chat-card .send-chat,
.chat-card .widget-chat-box .receive-chat,
.widget-chat-box .chat-card .receive-chat {
  text-align: right;
}

.chat-card .send-chat .msg,
.chat-card .widget-chat-box .receive-chat .msg,
.widget-chat-box .chat-card .receive-chat .msg {
  background: #ee2025;
  color: #fff;
  border-radius: 5px 0 5px 5px;
}

.chat-card .send-chat .msg:after,
.chat-card .widget-chat-box .receive-chat .msg:after,
.widget-chat-box .chat-card .receive-chat .msg:after {
  content: "";
  position: absolute;
  right: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-right-color: #ee2025;
}

.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  display: inline-block;
  float: right;
  padding: 0;
  position: absolute;
}

@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right {
    display: none;
  }
}

.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}

.card .card-header .card-header-right .dropdown-menu li {
  cursor: pointer;
}

.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}

.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #888;
}

.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}

.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}

.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 8px;
}

.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span {
  background-color: #888;
  height: 2px;
  border-radius: 5px;
}

.card
  .card-header
  .card-header-right
  .btn.dropdown-toggle.mobile-menu
  span:after,
.card
  .card-header
  .card-header-right
  .btn.dropdown-toggle.mobile-menu
  span:before {
  border-radius: 5px;
  height: 2px;
  background-color: #888;
}

.card .card-header .card-header-right .nav-pills {
  padding: 0;
  box-shadow: none;
  background: transparent;
}

.chat-card .received-chat .msg {
  background: #fe7b6d;
  border-radius: 0 5px 5px 5px;
}

.chat-card .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-bottom-color: #fe7b6d;
}

.chat-card .received-chat-mentor .msg {
  background: #212f3c;
  border-radius: 0 5px 5px 5px;
}

.chat-card .received-chat-mentor .msg:after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-bottom-color: #212f3c;
}

.img-radius {
  border-radius: 50%;
}

.wid-40 {
  width: 40px;
}

.card-option i {
  color: white;
  font-size: 16px;
}

.form-icon button {
  background: #ee2025;
  color: white;
}

.form-icon button:hover {
  background: #db1014;
  color: whitesmoke;
}

.btn.btn-wakool {
  font-size: 14px;
  color: #fff;
  background: linear-gradient(90deg, #ee2025 0%, #d61c20 100%);
  display: inline-block;
  width: 145px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  /* bottom: 0;
  left: 0; */
  right: 23px;
  height: 170px;
  width: 170px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(82, 92, 105, 0.5);
  border-radius: 50%;
}

.overlay .upload {
  color: #f1f1f1;
  font-size: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.containerImage:hover .overlay {
  opacity: 1;
}

/* Sweet Alert css */
.swal-icon--error {
  border-color: #ff5252;
}

.swal-icon--error__line {
  background-color: #ff5252;
}

.swal-icon--warning {
  border-color: #ffba57;
}

.swal-icon--warning__body {
  background-color: #ffba57;
}

.swal-icon--warning__dot {
  background-color: #ffba57;
}

@-webkit-keyframes pulseWarning {
  0% {
    border-color: #ffc471;
  }

  to {
    border-color: #ffba57;
  }
}

@keyframes pulseWarning {
  0% {
    border-color: #ffc471;
  }

  to {
    border-color: #ffba57;
  }
}

.swal-icon--success {
  border-color: #9ccc65;
}

.swal-icon--success__ring {
  border: 4px solid rgba(156, 204, 101, 0.2);
}

.swal-icon--success__line {
  background-color: #9ccc65;
}

.swal-icon--info {
  border-color: #00acc1;
}

.swal-icon--info:after,
.swal-icon--info:before {
  background-color: #00acc1;
}

.swal-title {
  color: rgba(0, 0, 0, 0.65);
}

.swal-text {
  color: rgba(0, 0, 0, 0.64);
}

.swal-button {
  background-color: #4680ff;
}

.swal-button:not([disabled]):hover {
  background-color: #2d6eff;
}

.swal-button:active {
  background-color: #2d6eff;
}

.swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(70, 128, 255, 0.29);
}

.swal-button--cancel {
  color: #555;
  background-color: #efefef;
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #e8e8e8;
}

.swal-button--cancel:active {
  background-color: #d7d7d7;
}

.swal-button--cancel:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(116, 136, 150, 0.29);
}

.swal-button--danger {
  background-color: #ff5252;
}

.swal-button--danger:not([disabled]):hover {
  background-color: #ff3939;
}

.swal-button--danger:active {
  background-color: #ff3939;
}

.swal-button--danger:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(255, 82, 82, 0.29);
}

.swal-footer {
  text-align: center;
}

.swal-content__input:focus {
  border-color: rgba(70, 128, 255, 0.29);
}

.swal-content__textarea:focus {
  border-color: rgba(70, 128, 255, 0.29);
}
/* End Sweet css */

#whatsapp-cs ._1bpcM{
  position: fixed;
  z-index: 99999;
  background-color: rgb(79, 206, 93);
  color: rgb(255, 255, 255);
  font-size: 40px;
}

#whatsapp-cs ._2iWL7{
  position: fixed;
  z-index: 99999;
  bottom: 83px;
  margin-right: 9px;
}

@keyframes ldio-r7hrik64b6a {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.45,0,0.9,0.55)
  }
  0% {
    transform: translate(0,0)
  }
  50% {
    transform: translate(0,50px);
    animation-timing-function: cubic-bezier(0,0.45,0.55,0.9);
  }
  100% {
    transform: translate(0,0);
  }
}

.ldio-r7hrik64b6a div {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e15b64;
  left: 35px;
  top: 10px;
  animation: ldio-r7hrik64b6a 1s linear infinite;
}
.loadingio-spinner-ball-ooxn6yt3to {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-r7hrik64b6a {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-r7hrik64b6a div { box-sizing: content-box; }
/* generated by https://loading.io/ */


/* @media only screen and (max-width: 575px) {
  .RenderPdf .react-pdf__Document .react-pdf__Page canvas{
    width: 100% !important;
    height: auto !important;
  }
} */

.RenderPdf .react-pdf__Document .react-pdf__Page canvas{
  width: 90% !important;
  height: auto !important;
  margin-bottom: 30px;
}

.RenderPdf .react-pdf__Document .react-pdf__Page .react-pdf__Page__annotations{
  display: none;
}
.RenderPdf .react-pdf__Document .react-pdf__Page .react-pdf__Page__textContent{
  display: none;
}


.user-profile-list table tbody tr .overlay-edit {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  background: whitesmoke;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-profile-list table tbody tr .overlay-edit .btn {
  border-radius: 50%;
  margin: 0 3px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.user-profile-list table tbody tr:hover td .overlay-edit {
  opacity: 1;
}

.user-profile-list table {
  /* border-spacing: 0 10px; */
  width: calc(100% - 10px);
  margin: 0 5px;
}

.user-profile-list table tbody tr {
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
  border-radius: 0.25rem;
  position: relative;
}

.user-profile-list table tbody #empty {
  box-shadow: none;
  /* border-radius: 0;
  position: relative; */
}

.user-profile-list table tbody tr .overlay-edit {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  background: whitesmoke;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-profile-list table tbody tr .overlay-edit .btn {
  border-radius: 50%;
  margin: 0 3px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.user-profile-list table tbody tr:hover {
  box-shadow: 0 0 6px 0 rgb(199, 198, 198);
}

.user-profile-list table tbody #empty:hover {
  box-shadow: none;
}

.user-profile-list table tbody tr:hover td {
  cursor: pointer;
  color: black;
  background: whitesmoke;
}

.user-profile-list table tbody #empty:hover td {
  cursor: default;
  color: black;
  background: none;
}

.user-profile-list table tbody tr:hover td h6 {
  color: black;
}

.user-profile-list table tbody tr:hover td .overlay-edit {
  opacity: 1;
}

.user-profile-list table tr td,
.user-profile-list table tr th {
  vertical-align: middle;
  border: none;
}

.user-profile-list table tr td {
  background: #fff;
  position: relative;
}

.user-profile-list table tr td:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.user-profile-list table tr td:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: rgba(70, 128, 255, 0.03);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.dataTable-style thead{
  background-color: #ecf0f5;
  color: #37474f;
  text-transform: uppercase;
  font-size: 14px;
}

.dataTable-style td,
.dataTable-style th {
    border-top: 1px solid #e2e5e8;
    white-space: nowrap;
    padding: 1.05rem 0.75rem;
}

.page-item.active .page-link{
  background-color: #EE2025;
  border-color: #EE2025;
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-pointer:hover{
  color: #EE2025;
}


#renderSertifikat{
  height: 830px;
  padding: 60px;
}

@media (max-width: 575.98px) {
  #renderSertifikat {
    height: 296px;
    padding: 20px;
  }
}


.wrapSet {
  position: relative;
}

.certificate {
  opacity: 1;
  /* display: block; */
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.wrapSet:hover .certificate {
  opacity: 0.3;
}

.wrapSet:hover .middle {
  opacity: 1;
}
.timeline-control::-webkit-media-controls-timeline {
  display: none;
}


.chat-online {
  color: #34ce57
}

.chat-offline {
  color: #e4606d
}

.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  overflow-y: scroll
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}
.py-3 {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}
.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}
.flex-grow-0 {
  flex-grow: 0!important;
}
.border-top {
  border-top: 1px solid #dee2e6!important;
}
.btn-custom{    
  font-size: 16px;
  color: #fff;
  background: linear-gradient(90deg,#EE2025 0%,#d61c20 100%);
  display: inline-block;
  /* width: 100%;
  height: 40px; */
  font-weight: 500;
  border: none;
  padding: 9px;
  border-radius: 5px;}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff8080;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%);
}

.banner-info{
  margin-top: 60px;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 20px;
  z-index: 9;
  box-shadow: 0 12px 25px rgb(0 0 0 / 7%);
  border-radius: 5px;
}

.banner-info button{
  font-size: 15px;
  color: #fff;
  background: #212F3D;
  width: 180px;
  height: 50px;
  border: none;
  border-radius: 5px;
}

.banner-info input{
  width: 100%;
  height: 50px;
  background-color: rgba(255,255,255,0.08);
  font-size: 15px;
  padding: 15px 20px;
  color: #182B49;
  border: 1px solid #212f3d;
  border-radius: 5px;
  margin-right: 20px;
}